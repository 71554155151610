import Image from "next/legacy/image";
import { ReactNode, SetStateAction } from "react";

interface MapMarkerProps {
    groupList: TripJournalGroup[];
    lat: number;
    lng: number;
    infoIndex: number;
    setActiveInfoWindow: React.Dispatch<SetStateAction<number>>;
    activeInfoWindow: number;
    children: ReactNode
}

const MapMarker = (props: MapMarkerProps): JSX.Element => {
    return (
        <>
            {
                props.infoIndex === props.activeInfoWindow &&
                props.children
            }

            {
                props.infoIndex !== props.activeInfoWindow &&
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    style={{
                        position: 'absolute',
                        transform: 'translate(-50%, -100%)',
                        cursor: 'pointer'
                    }}
                    src={'/images/map/journal-marker.png'}
                    width={'20px'}
                    height={'20px'}
                    alt="marker"
                    onClick={() => props.setActiveInfoWindow(props.infoIndex)}
                />
            }

        </>
    )
}

export default MapMarker